<template>
    <table align="right" class="tableClass" cellpadding="0" cellspacing="0">
      <tr>
        <th align="left" width="80">&nbsp;</th>
        <th align="left">动火等级</th>
        <th align="left">动火证</th>
        <th align="left">动火点</th>
      </tr>
      <tr v-for="(item, index) in list" :key="index">
        <td align="left" width="80">&nbsp;</td>
        <td>{{filterLevel(item.fireLevel)}}</td>
        <td>{{item.firePermitCount}}</td>
        <td>{{item.firePointCount}}</td>
      </tr>
    </table>
</template>
<script>
export default {
    name: 'collapseItem',
    props: {
      list: {
        type: Array,
        default: []
      }
    },
    data(){
        return {
            
        }
    },
    methods: {
      filterLevel(val){
        return val=='1' ? '一级' : (val=='2' ? '二级' : '三级')
      },
    }
}
</script>
<style lang="stylus" scoped>

</style>