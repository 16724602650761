<!--total数量显示-->
<template>
  <div class="breadcrumb-container">
    <el-breadcrumb>
      <el-breadcrumb-item
        replace
        v-for="bread in breadcrumbs"
        :to="{ path: bread.path }"
        :key="bread.name">
        {{bread.name}}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
  export default {
    name: 'Scg-breadcrumb',
    data() {
      return {
        breadcrumbs: [{ path: '/home', name: '首页' }],
      };
    },
    computed: {
      getCurrentSystem() {
        return this.$store.state.currentSystem;
      }
    },
    methods: {
      /**
       * 获取动态面包屑
       */
      getBreadCrumbs() {
        const current = {
          name: this.$route.meta.name || '',
          path: this.$route.fullPath,
        }
        this.breadcrumbs = this.calculateBread(
          JSON.parse(JSON.stringify(this.breadcrumbs)),
          current
        );
        localStorage.setItem('breadcrumbs', JSON.stringify(this.breadcrumbs));
      },

      /**
       * 动态计算面包屑显示（轮询）
       * @param oriBreadcrumbs
       * @param current
       * @return {*}
       */
      calculateBread(oriBreadcrumbs, current) {
        let latestBread = oriBreadcrumbs[oriBreadcrumbs.length - 1];
        let breadcrumbs = [];
        // 如果当前路由就是首页，直接展示初始的面包屑
        if (current.path === '/home' || current.path === '/') {
          breadcrumbs = [current];
        } else {
          // 如果当前路由和最后一个面包屑不是相同的，当前路由默认加入原面包屑（默认存在父子关系）
          if (current.path !== latestBread.path) {
            breadcrumbs = [...oriBreadcrumbs, current];
            // 如果当前路由的之前的最后一个路由（现在最后第二个路由）不是父子关系
            if (current.path.indexOf(latestBread.path) === -1) {
              // 如果原来不是在首页
              if (oriBreadcrumbs.length > 1) {
                // 如果没有父子关系就删除原最后一项
                breadcrumbs = oriBreadcrumbs.slice(0, oriBreadcrumbs.length - 1);
                if (breadcrumbs.length > 1) {
                  // 还没删到最后一项，进入轮询
                  return this.calculateBread(breadcrumbs, current);
                } else {
                  // 到首页后在首页后添加一项，结束
                  breadcrumbs = [...breadcrumbs, current];
                }
              }
            }
          } else {
            breadcrumbs = oriBreadcrumbs;
          }
        }
        return breadcrumbs;
      }
    },
    created() {
      // this.getBreadCrumbs();
      this.breadcrumbs =
        JSON.parse(localStorage.getItem('breadcrumbs'))
        || [{ path: '/home', name: '首页' }];
    },
    watch: {
      $route() {
        this.getBreadCrumbs();
      },
      getCurrentSystem: {
        handler(val, oldVal) {
          this.getBreadCrumbs();
        },
        immediate: true,
        deep: true,
      }
    },
  }
</script>

<style lang="stylus" scoped>
  @import "../styles/base.styl"
  .breadcrumb-container
    padding-top 15px
    padding-left 30px
    margin-bottom 24px
    display block
    height 54px
    line-height 54px
    background-color #fff
    box-sizing border-box
</style>

