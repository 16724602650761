<template>
  <div class="app-wrapper">
    <el-backtop :bottom="60" />
    <div class="sidebar-container" :style="{'width': isCollapse ? '64px':'256px'}">
      <div class="logo" :style="{'padding-left': isCollapse ? '15px':'26px'}">
        <img src="../../assets/pic_logo.png" width="202" />
      </div>
      <sidebar :collapse="isCollapse"></sidebar>
    </div>
    <div class="main-container" :style="{'margin-left': isCollapse ? '64px':'256px'}">
      <app-header
        class="tags-navbar"
        v-if="!$route.meta.hideNavBar"
        :companies="companies"
        @isCollapse="handleCollapse"
      />
      <scg-breadcrumb />
      <app-main
        class="main-content-container" />
    </div>
  </div>
</template>
<script>
import {AppMain, AppHeader, AppFooter} from './components'
import Sidebar from './components/Sidebar.vue'
import ScgBreadcrumb from '@/components/Scg-breadcrumb'
export default {
  name: 'Layout',
  components: {
    AppMain,
    AppHeader,
    AppFooter,
    Sidebar,
    ScgBreadcrumb
    
  },
  data() {
    return {
      isCollapse: false,
      showDialog: false,
      menus: [],
      companies: [],
    }
  },
  methods: {
    handleCollapse(val){
      console.log("layout:"+val)
      this.isCollapse = val
    },
    checkCollapse(val) {
      this.isCollapse = val
    },
  },
  watch: {
    // 根据公司id获取menutree
  }
}
</script>
<style lang="stylus" scoped>
.app-wrapper
  position relative
  height 100%
  width 100%
  .sidebar-container
    width 256px
    left 0
    bottom 0
    top 0
    position fixed
    z-index 999
    background-color #333
  .logo
    color #fff
    font-size 18px
    height 64px
    line-height 48px
    padding-top 16px
    // padding-left 26px
    box-sizing border-box
    background-color #333
    vertical-align middle
    
  .main-container
    margin 0 auto
    // margin-left 256px
    padding-top 64px
    display block
    // float left
    height auto
    // width 100%
    
    .sidebar-outer-container
      background-color #ececec
      width 250px
      z-index 100
      position fixed
      height 100%
      left 0
      top 90px
      padding-top 20px
      padding-right 20px
      box-sizing border-box
    .sidebar-container
      height 100%
      background-color #FFFFFF
      border solid 1px #d5d5d5
    .main-content-container
      width 100%
      box-sizing border-box
      // padding-left 238px
  .no_navbar
    margin-top 0
</style>