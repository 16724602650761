<template>
  <header class="app-header-container" :style="{'left': isCollapse ? '64px':'256px'}">
    <div class="header-content">
      <div class="menu-title-region"
         @click="handleCollapse"
         title="点击缩放菜单"
          :style="{'display' : ($route.meta.hideSideBar ? 'none' : 'block')}">
      </div>
      <div class="info-container">
        <el-dropdown style="min-width: 220px; display:inline-block" @command="handleCommand" placement="top-start">
          <span class="el-dropdown-link">
            <i class="icon-ic_standard" />
            {{ curCompany.companyName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="max-height:320px; overflow:auto">
            <el-dropdown-item :command="company" v-for="company in companies" :key="company.companyId">
              <span>{{ company.companyName }}</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span style="color:#000"><i class="el-icon-time"></i> {{currentTime | dateTimeFormat}}</span>
        <el-dropdown @command="handleClickSetting">
            <span class="el-dropdown-link">
              <el-avatar class="avatar_img" /> {{memberName}} | 管理员
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command='logout'>注销</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
export default {
    name: 'AppHeader',
    components: {

    },
    data(){
      return {
        timer:null,
        currentTime: '',
        curCompany: {
          "companyId":"1286025454121595392","companyName":"上海建工集团股份有限公司","type":null,"companyLevel":1
        },
        memberName: '',
        companyName: '',
        isCollapse: false,
        companies: [{
          "companyId":"1381114145562199040","companyName":"上海市机械施工集团有限公司","type":null,"companyLevel":2
        },{
          "companyId":"1381114833553884160","companyName":"上海建工四建集团有限公司","type":null,"companyLevel":2
        },{
          "companyId":"1381115427324724224","companyName":"上海建工七建集团有限公司","type":null,"companyLevel":2
        },{
          "companyId":"1381116124384494592","companyName":"上海建工设计研究院有限公司","type":null,"companyLevel":2
        },{
          "companyId":"1381116642297152512","companyName":"上海市安装工程集团有限公司","type":null,"companyLevel":2
        },{
          "companyId":"1286025454121595392","companyName":"上海建工集团股份有限公司","type":null,"companyLevel":1
        }]
      }
    },
    methods: {
      handleCommand(){},
      /**
       * 缩放菜单
       */
      handleCollapse() {
        console.log(this.isCollapse)
        this.isCollapse = !this.isCollapse
        this.$emit('isCollapse', this.isCollapse)
      },
      /**
       * 下拉列表命令：1.logout退出
       * @param command
       */
      handleClickSetting(command){
          if(command == 'logout'){
              this.logout()
          }
      },
      /**
       * 退出登录
       */
      logout(){
          localStorage.clear();
          this.$router.push({
              path: '/login'
          })
      }
    },
    mounted(){
      let _this = this
      this.timer = setInterval(() => {
        _this.currentTime = new Date()
      }, 1000)
      this.memberName = localStorage.getItem('memberName') || '无用户名';
      this.companyName = localStorage.getItem('companyName') || '无公司名';
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
}
</script>
<style lang="stylus" scoped>
@import "../../../styles/base.styl"
.app-header-container
  height 64px
  background #fff
  position fixed
  // left 256px
  top: 0
  right 0
  z-index 999
  box-sizing border-box
  color #fff
  box-shadow 0px 1px 4px 0px rgba(0, 21, 41, 0.12)
  .avatar_img
    width 24px
    height 24px
    background url("../../../assets/user.png")
    margin 0 15px 0 15px
    background-size cover
    background-position center // 完全居中
  .header-content
    // width 1233px
    margin 0 auto
    margin-top 20px
    margin-right 29px
    display flex
    justify-content space-between
  .menu-title-region
    width 12px
    cursor pointer
    vertical-align middle
    text-align center
    margin 0 22px 24px
    font-size 18px
    font-family $title-family
    font-weight $title-weight
    color rgba(152, 166, 172, 1)
    height 20px
    line-height 20px
    background url('../../../icons/png/icon-collapse.png') #fff no-repeat center center

    i
      left -5px
      position absolute
      top 14px

    &:hover
      color $menu-hover-color
  .logo
    float left
    font-size 32px
    color #ffffff
    font-weight bold
    line-height 90px
    padding-left 20px
  .info-container
    font-size 14px
    color #ffffff
    // line-height 90px
    .el-dropdown-link
      font-size 14px
      cursor pointer
</style>