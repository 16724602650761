<template>
  <div id="index" class="enterprise-site-screen-container">
    <img class="bg-img" src="../../../assets/background.png" />
    <!-- 底部 start -->
    <img class="bottom-img" src="../../../assets/background-bottom.png" />
    <div class="copyrightDiv"></div>
    <!-- 底部 end -->
    <!-- 顶部 start -->
    <img class="banner" src="../../../assets/banner.png" />
    <img class="logo-img" src="../../../assets/logo_screen.png" />
    <div class="logoDiv">{{ enterpriseName }}</div>
    <button class="qiehuan" @click="changeLook"></button>
    <div class="time2">{{nowTime2}}</div>
    <div class="time">{{nowTime}}<span style="margin-left: 20px">{{week}}</span></div>
    <!-- 顶部 end -->

    <div class="bg-img" style="z-index: 0">
      <!--      左侧盒子-->
      <div class="left">

        <div class="leftBox">
          <div class="title">动火点等级分类统计</div>
          <!-- <dv-scroll-board :config="config" style="margin: 3%; width:83%; height:350px" /> -->
          <div style="width: 90%; height: 200px; display: flex;">
            <div style="flex: 1;" id="fire_level_pie_chart"/>
          </div>
        </div>

        <div class="leftBox1">
          <div class="title">动火点统计</div>
          <!-- <div id="donghuoLevelStatistic_chart"/> -->
<!--          <dv-scroll-board :config="config2" style="margin: 3%; width:83%; height:350px" />-->
            
          <div class="el-menu-vertical-demo" ref="menu">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item :name="String(i+1)" v-for="(item,i) in allPointsData" :key="i+1">
                <div slot="title" class="collapse-title"><img src="../../../assets/icon-group.png" width="18" height="18" /> {{item.companyName}}</div>
                <el-collapse accordion class="secondCollapse" v-for="(subCompany,j) in item.subCompanyStatisInfoList" :key="j">
                  <el-collapse-item>
                    <div slot="title" class="collapse-title"><img src="../../../assets/icon-subcompany.png" width="18" height="18" /> {{subCompany.companyName}}</div>
                    
                    <el-collapse accordion class="thirdCollapse" v-for="(thirdCompany,l) in subCompany.subCompanyStatisInfoList" :key="l">
                      <el-collapse-item>
                        <div slot="title" class="collapse-title"><img src="../../../assets/icon-subcompany.png" width="18" height="18" /> {{thirdCompany.companyName}}</div>
                      </el-collapse-item>
                    </el-collapse>
                    <el-collapse accordion class="thirdCollapse" v-for="(thirdProject,m) in subCompany.projectStatisInfoList" :key="m">
                      <el-collapse-item>
                        <div slot="title" class="collapse-title"><img src="../../../assets/icon-project.png" width="18" height="18" />  {{thirdProject.projectName}} <el-button size="mini" type="text" @click="jumpToProjectScreen(thirdProject)">进入>></el-button></div>
                        <scg-table :list="thirdProject.firePointStatis" v-if="thirdProject.firePointStatis && thirdProject.firePointStatis.length>0"></scg-table>
                      </el-collapse-item>
                    </el-collapse>
                    
                  </el-collapse-item>
                </el-collapse>
                <el-collapse accordion class="secondCollapse" v-for="(project,k) in item.projectStatisInfoList" :key="k">
                  <el-collapse-item>
                    <div slot="title" class="collapse-title"><img src="../../../assets/icon-project.png" width="18" height="18" /> {{project.projectName}}  <el-button size="mini" type="text" @click="jumpToProjectScreen(project)">进入>></el-button></div>
                    <scg-table :list="project.firePointStatis" v-if="project.firePointStatis && project.firePointStatis.length"></scg-table>
                  </el-collapse-item>
                </el-collapse>
              </el-collapse-item>
            </el-collapse>
          </div>

        </div>
      </div>

      <!--      中间盒子-->
      <div class="center">
        <div class="centerBox" v-if="change==true">
          <baidu-map
              class="map"
              :center="center"
              :zoom="zoom"
              :scroll-wheel-zoom="true"
              :continuous-zoom="true"
              @ready="handler">
            <template>
              <bm-marker :position="item" v-for="(item, index) in newPoints" :key="index" @click="look(item)"></bm-marker>
              <bm-info-window :title="from.projectName" :position="from.position" :show="showInfoWindow" @close="infoWindowClose" @open="infoWindowOpen()">
                <div style="color:#fff; font-size:16px;">
                  <div style="margin-top:5px; height: 2px; width:100%;background-color: #1981E1 !important;"></div>
                  <div style="margin-top:10px;">建设单位： {{from.buildingCompany}} </div>
                  <div style="margin-top:5px;">项目经理：{{from.projectLeader}}</div>
                  <div style="margin-top:5px;">计划开工日期：{{from.startDate | format('YYYY-MM-DD')}}</div>
                  <div style="margin-top:5px;">计划竣工日期：{{from.completeDate | format('YYYY-MM-DD')}}</div>
                </div>
                <el-button size="mini" class="enterButton" @click="jumpToProjectScreen(from)">进入</el-button>
              </bm-info-window>
            </template>
            <bm-map-type :map-types="['BMAP_NORMAL_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
          </baidu-map>
        </div>

        <div class="centerBox" v-if="change==false">
          <div style="margin-left: 2%;padding-top: 5px;font-size: 18px; display: inline-block">分屏:</div>
          <div style="width: 30%; margin-left: 40px; display: inline-block">
            <span style="cursor: pointer; margin-right: 15px;" :type="classtype1"  @click="getVideoNumInScreen(1)">
              <el-tooltip effect="dark" content="1分屏" placement="bottom">
                <img src="../../../assets/1ping.svg" v-if="classtype1!='primary'">
                <img src="../../../assets/1pingYellow.svg" v-if="classtype1=='primary'">
              </el-tooltip>
            </span>
              <span style="cursor: pointer; margin-right: 10px" :type="classtype2"  @click="getVideoNumInScreen(2)">
              <el-tooltip effect="dark" content="4分屏" placement="bottom">
                <img src="../../../assets/4ping.svg" v-if="classtype2!='primary'">
                <img src="../../../assets/4pingYellow.svg" v-if="classtype2=='primary'">
              </el-tooltip>
            </span>
              <span style="cursor: pointer; margin-right: 15px" :type="classtype3"  @click="getVideoNumInScreen(3)">
              <el-tooltip effect="dark" content="9分屏" placement="bottom">
                <img src="../../../assets/9ping.svg" v-if="classtype3!='primary'">
                <img src="../../../assets/9pingYellow.svg" v-if="classtype3=='primary'">
              </el-tooltip>
            </span>
              <span style="cursor: pointer; margin-right: 15px" :type="classtype4"  @click="getVideoNumInScreen(4)">
              <el-tooltip effect="dark" content="16分屏" placement="bottom">
                <img src="../../../assets/16ping.svg" v-if="classtype4!='primary'">
                <img src="../../../assets/16pingYellow.svg" v-if="classtype4=='primary'">
              </el-tooltip>
            </span>
          </div>
          <!--          <devide-video ref="child"></devide-video>-->
          <div class="hotwork_video_container">
            <div class="hotwork_video_content" id="container">
              <!-- {{playerHeight}}-{{heightRatio}}-{{rowNum}} -->
              <!-- {{total}}-{{pageNum}}-{{newVideoList}} -->
              <div class="hotwork_video_main">
                <el-carousel :interval="6000" :height="playerHeight+'px'">
                  <el-carousel-item v-for="pitem in newVideoList.length" :key="pitem">
                    <!-- {{pitem}}-pageSize:{{pageSize}} -->
                    <el-row class="grid_region">
                      <el-col :span="rowNum" v-for="(item,index) in newVideoList[pitem-1]" :key="index">
                        <div class="video" ref="video_area" v-if="item.webrtcUrl && item.webrtcUrl!==''">
                          <div :style="{'width':'100%','background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}">
                            <LivePlayer :videoUrl="item.webrtcUrl" fluent autoplay live stretch></LivePlayer>
                          </div>
<!--                          <div class="block" style="width:60px; z-index:999; position:absolute; right:5px; top:0; color:#0ff; font-size:12px; text-align:center">
                            <span class="demonstration" style="line-height:30px;">告警音量</span>
                            <el-slider v-model="item.alarmVolumn" @change="adjustVolume(item)" :max="225"></el-slider>
                          </div>-->
                          <div class="block" style="min-width:60px; z-index:999; position:absolute; left:5px; top:0; color:#0ff; font-size:12px; text-align:center">
                            <span class="demonstration" style="line-height:21px;">{{ item.projectAbbreviation }}：{{item.label}}</span>
                          </div>
                        </div>
                        <div :style="{'width':'100%','background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}" v-else>
                          <i class="el-icon-video-camera"></i>
                        </div>
                      </el-col>
                      <template v-if="newVideoList[pitem-1].length<pageSize">
                        <el-col :span="rowNum" v-for="(bgitem,i) in pageSize-currentVideoList.length" :key="i">
                          <div :style="{'background-color':'#000', 'border':'solid 1px #fff','height':playerHeight/heightRatio+'px','line-height':playerHeight/heightRatio+'px'}">
                            <i class="el-icon-video-camera"></i>
                          </div>
                        </el-col>
                      </template>
                    </el-row>
                  </el-carousel-item>
                </el-carousel>

              </div>
            </div>
          </div>

        </div>
      </div>


      <!--      右侧盒子-->
      <div class="right">
        <div class="rightBox">
          <div class="title">今日动火人员统计</div>
          <div style=" width: 30%; height: 200px; margin-top: 3%; margin-left: 3%; float: left; display: flex;">
            <div style="flex: 1;">
              <div style="margin-left: 10px;margin-top: 10%; font-size: 16px; color: #F0F0F0; font-weight: normal;">总人数累计</div>
              <div style="margin-top: 10px; margin-left: 10px; color: #00D8FE; font-size: 40px; font-weight: 500; text-align: left">{{totalStaffNum}}</div>
              <div class="divider-line"></div>
              <div style="margin-left: 10px;margin-top: 10%; font-size: 16px; color: #F0F0F0; font-weight: normal;">今日出勤人数</div>
              <div style="margin-top: 10px; margin-left: 10px; color: #77FA89; font-size: 40px; font-weight: 500; text-align: left">{{ attendanceCount }}</div>
            </div>
            
          </div>
          
          <div style=" width: 50%; height: 200px; margin-left: 37%;  display: flex;">
            <div style="flex: 1;" id="pie_chart"/>
          </div>
        </div>

        <div class="rightBox1">
          <div class="title">今日告警统计</div>
          <div id="todayAlarm_chart"/>
        </div>

        <div class="rightBox1">
          <div class="title">告警信息统计（一年内）</div>
          <div id="alarmStatistic_chart"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LivePlayer from '@liveqing/liveplayer'
import ScgTable from './components/Table'
export default {
  name: 'enterpriseSiteScreen',
  components: {
    LivePlayer,
    ScgTable
  },
  data() {
    return {
      enterpriseName: '',
      allPointsData: [],
      activeName: '1',
      change: true,
      config: {},
      config2:{},
      classtype1:'',
      classtype2:'primary',
      classtype3:'',
      classtype4:'',
      fullscreen: false,

      nowTime: new Date(),
      nowTime2: new Date(),
      week: new Date(),

      zoomShow: null,
      endValue:null,
      zoomShow2: null,
      endValue2:null,

      donghuoLevelxData:[//动火等级分类柱状图数据
        ['project', '双视红外', '智能分析', '人像采集'],
      ],

      center: {lng: 121.403573, lat: 31.244107},
      zoom: 12,
      newPoint:{
        lng: 0,
        lat: 0
      },
      newPoints:[],
      showInfoWindow: false,

      staffData:[],
      totalStaffNum: 0, // 今日动火人员统计：总人数累计
      attendanceCount: 0, // 今日动火人员统计：今日出勤人数

      todayAlarmList:[],
      todayAlarmCount:[],

      rotate:null,

      AlarmList:[],
      AlarmCount:[],

      fireList:[],
      deviceList:[],

      from:{},

      videoList: [],
      playerHeight:'',
      rowNum:24,
      pageNum: 0, // 页码
      pageSize: 0, // 每页记录数
      total:0, // 总记录数
      currentVideoList:[], // 当前项目
      // currentPage: 1, // 当前页面
      newVideoList:[],
      heightRatio:1
    }
  },

  mounted() {
    this.timer = setInterval(() => {
      this.nowTime = this.$moment(new Date().toLocaleString()).format("YYYY-MM-DD");
      this.nowTime2 = this.$moment(new Date().toLocaleString()).format("HH: mm: ss");

      let wk = new Date().getDay()
      let weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      this.week = weeks[wk]
    });


    if (this.donghuoLevelxData.length > 10) {//this.xData是横轴的数据，通过后台动态获取的
      this.zoomShow = true;//通过横轴数据多少来判断滚动条是否显示(官网说滚动条不显示，但过滤数据的功能还在，过滤数据请移步官网去看具体配置项说明)
      this.endValue = (10 / this.donghuoLevelxData.length) * 100;
    } else {
      this.zoomShow = false;
      this.endValue = 100;//这里一定要注意不显示滚动条的时候一定要把滚动条结束位置设置一下，不然会有bug
    }
  },

  created() {
    this.getStaffData()
    this.getTodayAlarm()
    this.getAlarmStatis()
    this.getFireData()
    this.getDeviceData()
    
    this.getPoint()
    this.getAllPointStatis()

  },

  methods: {
    changeLook(){
      this.change=!this.change
      if(this.change==false){
        this.getVideo()
      }

    },
    // val 画面 1：1，2：4，3：9，4：16
    getVideoNumInScreen(val){
      this.heightRatio=val
      this.playerHeight=document.getElementById("container").offsetWidth/val*454/807 * this.heightRatio;
      // console.log(val);
      this.newVideoList=[]
      this.rowNum = 24/val;
      this.pageSize = Math.pow(val, 2)
      // console.log("pageSize:"+this.pageSize)
      this.pageNum = Math.ceil(this.total/this.pageSize); // 平方再向上取整
      for(let i=0;i<=this.pageNum-1;i++){
        // console.log("i:"+i)
        // console.log(this.pageSize)
        let remain= i==this.pageNum-1 ? (this.videoList.length%this.pageSize==0?this.pageSize :  this.videoList.length%this.pageSize): this.pageSize
        // console.log("remain:"+remain)
        let list=[]
        for(let j=0; j<=remain-1;j++){
          // console.log("j:"+j)
          // console.log(i,j,i*this.pageSize+j)
          list.push(this.videoList[i*this.pageSize+j])

        }
        this.newVideoList[i]=list
      }


      if(val==1){
        this.classtype1='primary'
        this.classtype2=''
        this.classtype3=''
        this.classtype4=''
      }
      else if(val==2){
        this.classtype1=''
        this.classtype2='primary'
        this.classtype3=''
        this.classtype4=''
      }
      else if(val==3){
        this.classtype1=''
        this.classtype2=''
        this.classtype3='primary'
        this.classtype4=''
      }
      else if(val==4){
        this.classtype1=''
        this.classtype2=''
        this.classtype3=''
        this.classtype4='primary'
      }
    },

    getVideo() {
      let data = {
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/list', data).then(res => {
            if (res.code === 200) {
              this.videoList=[]
              let arr=[]
              console.log(res.data.projectsAndHotwork)
              if(res.data.projectsAndHotwork && res.data.projectsAndHotwork.length>0){
                res.data.projectsAndHotwork.forEach(item=>{
                  if(item.hotWorkPermits && item.hotWorkPermits.length>0){
                    item.hotWorkPermits.forEach(subItem=>{
                      arr.push({
                        ...subItem,
                        projectName: item.projectName,
                        projectAbbreviation: item.projectAbbreviation
                      })
                    })
                  }
                })
              }
              
              console.log('视频列表获取~')
              console.log(arr)
              for(let item of arr){
                // flvUrl,m3u8Url,webrtcUrl,wsFlvUrl
                if(item.m3u8Url && item.m3u8Url!==''){
                  this.videoList.push({
                    id: item.hotWorkPermitCode,
                    label: item.hotWorkPointName,
                    deviceCode: item.deviceCode,
                    webrtcUrl: item.m3u8Url, // 
                    alarmVolumn: 100,
                    projectName: item.projectName,
                    projectAbbreviation: item.projectAbbreviation
                  })
                }

              }
              this.videoList=[{
                    id: '1',
                    label: 'flvUrl',
                    deviceCode: '001',
                    webrtcUrl: 'https://firemanage.igongdi.top:10010/sms/34020000002020000001/flv/hls/0098429ffa56_0098429ffa56.flv', // 
                    alarmVolumn: 100,
                    projectName: 'test',
                    projectAbbreviation: 'j1'
                  },{
                    id: '2',
                    label: 'm3u8Url',
                    deviceCode: '001',
                    webrtcUrl: 'https://firemanage.igongdi.top:10010/sms/34020000002020000001/hls/0098429ffa56_0098429ffa56/0098429ffa56_0098429ffa56_live.m3u8', // 
                    alarmVolumn: 100,
                    projectName: 'test',
                    projectAbbreviation: 'j1'
                  },{
                    id: '3',
                    label: 'webrtcUrl',
                    deviceCode: '001',
                    webrtcUrl: 'webrtcs://firemanage.igongdi.top:10010/sms/34020000002020000001/rtc/0098429ffa56_0098429ffa56', // 
                    alarmVolumn: 100,
                    projectName: 'test',
                    projectAbbreviation: 'j1'
                  },{
                    id: '4',
                    label: 'wsFlvUrl',
                    deviceCode: '001',
                    webrtcUrl: 'wss://firemanage.igongdi.top:10010/sms/34020000002020000001/ws-flv/hls/0098429ffa56_0098429ffa56.flv', // 
                    alarmVolumn: 100,
                    projectName: 'test',
                    projectAbbreviation: 'j1'
                  }]
      //         [{
      //   "label":"flvUrl",
      //   "webrtcUrl":"https://firemanage.igongdi.top:10010/sms/34020000002020000001/flv/hls/005fddf7b2ce_005fddf7b2ce.flv",
      // },{
      //   "label":"m3u8Url",
      //   "webrtcUrl":"https://firemanage.igongdi.top:10010/sms/34020000002020000001/hls/005fddf7b2ce_005fddf7b2ce/005fddf7b2ce_005fddf7b2ce_live.m3u8",
      // },{
      //   "label":"webrtcUrl",
      //   "webrtcUrl":"webrtcs://firemanage.igongdi.top:10010/sms/34020000002020000001/rtc/005fddf7b2ce_005fddf7b2ce",
      // },{
      //   "label":"wsFlvUrl",
      //   "webrtcUrl":"wss://firemanage.igongdi.top:10010/sms/34020000002020000001/ws-flv/hls/005fddf7b2ce_005fddf7b2ce.flv",
      // }]
              this.total = this.videoList.length
              this.getVideoNumInScreen(1)

            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
// 调节视频音量
    /*adjustVolume(obj){
      const params = {
        deviceId: obj.deviceCode,
        volume: obj.alarmVolumn, // 动火许可证编码
      }
      this.$post(FIRE_MANAGE_MQTT_DEVICE_VOLUME, params).then((res) => {
        console.log(res)
        if(res.code===200){
          console.log(res.data)
          // if(res.data){
          //   this.approvalInfo = res.data.firePermits[0]
          // }
        }
      })
    },*/
    //动火设备统计柱状图
    donghuoLevelStatisticChart(){
      let myChart = this.$echarts.init(document.getElementById('donghuoLevelStatistic_chart'));
      let option = {
        dataZoom: [
          {
            orient: "horizontal",
            textStyle: false,//取消滚动条旁边两侧的提示字
            show: this.zoomShow,//控制滚动条显示隐藏
            realtime: true, //拖动滚动条时是否动态的更新图表数据
            height: 0, //滚动条高度
            start: 0, //滚动条开始位置（共100等份）
            end: this.endValue,//滚动条结束位置
            bottom: "10%",
            zoomLock: true, //控制面板是否进行缩放
            startValue:0,//从xData的第几条开始
            endValue:4,//到xData的第几条介绍
          }],
        color: ['#00F5F7', '#FFEC3D', '#FA541C'],
        legend: {
          top: 'top',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        tooltip: {},
        dataset: {
          source: this.donghuoLevelxData
        },
        xAxis: [
          {
            type : 'category',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 10,
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:0,
                type:'solid',
              }
            },
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type : 'value',
            name:'单位：个',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 14
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:1,
                type:'solid',
              }
            },
          }
        ],
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };

      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },

    //构建今日告警柱状图
    buildTodayAlarmChart(){
      let myChart = this.$echarts.init(document.getElementById('todayAlarm_chart'));
      let option = {
        color: ['#FF4D4F'],
        tooltip : {
          trigger: 'axis',
          axisPointer : {
            type : 'shadow'
          }
        },
        xAxis : [
          {
            type : 'category',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 8,
              rotate: this.rotate
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:0,
                type:'solid',
              }
            },

            data : this.todayAlarmList,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis : [
          {
            type : 'value',
            name:'单位：次',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 14
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:1,
                type:'solid',
              }
            },
          }
        ],
        series : [
          {
            name:'',
            type:'bar',
            barWidth: '60%',
            data: this.todayAlarmCount,
            itemStyle: {
              //通常情况下：
              normal:{
                //每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
                color: function (params){
                  var colorList = ['#722ED1','#FFA940','#FF4D4F','#18ffba','#722ED1','#FFA940','#FF4D4F','#18ffba'];
                  return colorList[params.dataIndex];
                }
              },
            },
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },

    //构建告警统计折线图
    buildAlarmStatisticChart(){
      let myChart = this.$echarts.init(document.getElementById('alarmStatistic_chart'));
      let option = {
        dataZoom: [
          {
            orient: "horizontal",
            textStyle: false,//取消滚动条旁边两侧的提示字
            show: this.zoomShow2,//控制滚动条显示隐藏
            realtime: true, //拖动滚动条时是否动态的更新图表数据
            height: 0, //滚动条高度
            start: 0, //滚动条开始位置（共100等份）
            end: this.endValue2,//滚动条结束位置
            bottom: "10%",
            zoomLock: true, //控制面板是否进行缩放
            startValue:0,//从xData的第几条开始
            endValue:4,//到xData的第几条介绍
          }],
        color: ['#FF7A45'],
        tooltip : {
          trigger: 'axis',
          axisPointer : {
            type : 'shadow'
          }
        },
        grid: {
          left: 60, // 网格离左边的距离：调整y坐标的宽度
        },
        xAxis : [
          {
            type : 'category',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 8,
              rotate: this.rotate
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:0,
                type:'solid',
              }
            },

            data : this.AlarmList,
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis : [
          {
            type : 'value',
            name:'单位：次',
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 14
            },
            axisLine:{
              show: true,
              lineStyle:{
                color:'#ffffff',
                width:1,
                type:'solid',
              }
            },
          }
        ],
        series : [
          {
            name:'',
            type:'line',
            barWidth: '60%',
            data:this.AlarmCount
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },

    //构建饼图
    buildPieChart(){
      let myChart = this.$echarts.init(document.getElementById('pie_chart'));
      let option = {
        title: {
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#722ED1','#FFA940','#FF4D4F','#18ffba','#18FF1CFF','gray'],
        legend: {
          top: 'bottom',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '45%',
            center: ['50%', '40%'],
            data: this.staffData,
            label: {
              normal: {
                show: true,
                formatter: '{d}%',
                color:'#FFFFFF',
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },

    //地图样式Json以及点坐标编写
    handler ({BMap, map}) {
        let that = this;
        that.map = map;
        map.setMapStyleV2(
            {
              // styleId:'73979e40c609f11f44227183a85a0f42'
              styleJson:[{
                "featureType": "land",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#091220ff"
                }
              }, {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#113549ff"
                }
              }, {
                "featureType": "green",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#0e1b30ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.topfill",
                "stylers": {
                  "color": "#113549ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.sidefill",
                "stylers": {
                  "color": "#143e56ff"
                }
              }, {
                "featureType": "building",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#dadada00"
                }
              }, {
                "featureType": "subwaystation",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#113549B2"
                }
              }, {
                "featureType": "education",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "medical",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "4"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#fed66900"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "2"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "arterial",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffeebb00"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "arterial",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on",
                  "weight": "1"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "local",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "local",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "local",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#979c9aff"
                }
              }, {
                "featureType": "local",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "railway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "weight": "1"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#d8d8d8ff"
                }
              }, {
                "featureType": "subway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#979c9aff"
                }
              }, {
                "featureType": "subway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "continent",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "city",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "city",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "town",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#454d50ff"
                }
              }, {
                "featureType": "town",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "districtlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "road",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "district",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "poilabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "manmade",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "districtlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffffff"
                }
              }, {
                "featureType": "entertainment",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "shopping",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "stylers": {
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "10",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-10"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "stylers": {
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "6",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "7",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "8",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off",
                  "level": "9",
                  "curZoomRegionId": "0",
                  "curZoomRegion": "6-9"
                }
              }, {
                "featureType": "subwaylabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "subwaylabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "tertiarywaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "provincialwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "provincialwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "nationalwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "nationalwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "highwaysign",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "highwaysign",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "village",
                "elementType": "labels",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "district",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "country",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "country",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff10"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "highway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "nationalway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "provincialway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text",
                "stylers": {
                  "fontsize": "20"
                }
              }, {
                "featureType": "cityhighway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "estate",
                "elementType": "geometry",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "tertiaryway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspotsway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "scenicspotsway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "universityway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "universityway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "vacationway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "vacationway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.fill",
                "stylers": {
                  "color": "#12223dff"
                }
              }, {
                "featureType": "fourlevelway",
                "elementType": "geometry.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "transportationlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels",
                "stylers": {
                  "visibility": "on"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "educationlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportation",
                "elementType": "geometry",
                "stylers": {
                  "color": "#113549ff"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medicallabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "scenicspotslabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "airportlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "entertainmentlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "estatelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "businesstowerlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "companylabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "governmentlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "restaurantlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "hotellabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "shoppinglabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "lifeservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "carservicelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "financelabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "otherlabel",
                "elementType": "labels.icon",
                "stylers": {
                  "visibility": "off"
                }
              }, {
                "featureType": "manmade",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "manmade",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "transportation",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "transportation",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "education",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "education",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "medical",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "medical",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "labels.text.fill",
                "stylers": {
                  "color": "#2dc4bbff"
                }
              }, {
                "featureType": "scenicspots",
                "elementType": "labels.text.stroke",
                "stylers": {
                  "color": "#ffffff00"
                }
              }]
            }
        )




    },

    look(item){
      console.log(item)
      this.from = item
      this.from.position = {
        lng: item.lng,
        lat: item.lat
      }
      this.showInfoWindow = true;
    },

    infoWindowClose () {
      this.showInfoWindow = false
    },
    infoWindowOpen (item) {
      this.showInfoWindow = true
    },

    //进入项目端大屏
    jumpToProjectScreen(project){
      console.log(project)
      window.open("https://firemanage.igongdi.top/#/projectSiteScreen?projectCode="+project.projectCode, '_blank')
      // window.open("http://localhost:8081/#/projectSiteScreen?projectCode="+project.projectCode, '_blank') // 本地测试
    },

    //获取饼图数据
    getStaffData(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/member/age', data).then(res => {
            if (res.code === 200) {
              this.totalStaffNum=res.data.totalCount
              this.attendanceCount = res.data.attendanceCount
              let arr=res.data.workerStatisInfos
              for(let i in arr){
                if(arr[i].ageType==1){
                  arr[i].ageType='19岁以下'
                }else if(arr[i].ageType==2){
                  arr[i].ageType='19-25岁'
                }else if(arr[i].ageType==3){
                  arr[i].ageType='26-35岁 '
                }else if(arr[i].ageType==4){
                  arr[i].ageType='36-45岁'
                }else if(arr[i].ageType==5){
                  arr[i].ageType='46-55岁 '
                }else if(arr[i].ageType==6){
                  arr[i].ageType='55岁以上'
                }
              }
              for (let i = 0; i < arr.length; i++) {
                let res = JSON.parse(
                    JSON.stringify(arr[i])
                        .replace(/num/g, 'value')
                        .replace(/ageType/g, 'name')
                );
                this.staffData.push(res);
              }
              this.buildPieChart()
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //获取今日告警
    getTodayAlarm(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/today/statis', data).then(res => {
            if (res.code === 200) {
              let arr=res.data
              if(res.data.length>0 && res.data.length!=null){
                for(let i in arr){
                  this.todayAlarmList.push(arr[i].alarmMessage)
                  this.todayAlarmCount.push(arr[i].count)
                  this.rotate=60
                }
              }else{
                this.todayAlarmList=['暂无数据']
                this.todayAlarmCount=[0]
                this.rotate=0
              }

              this.buildTodayAlarmChart()
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //获取告警统计
    getAlarmStatis(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/alarm/statis', data).then(res => {
            if (res.code === 200) {
              let arr=res.data
              if(res.data.length>0 && res.data.length!=null){
                for(let i in arr){
                  this.AlarmList.push(arr[i].alarmMessage)
                  this.AlarmCount.push(arr[i].count)
                  this.rotate=60
                }
              }else{
                this.AlarmList=['暂无数据']
                this.AlarmCount=[0]
                this.rotate=0
              }

              if (this.AlarmList.length > 10) {//this.xData是横轴的数据，通过后台动态获取的
                this.zoomShow2 = true;//通过横轴数据多少来判断滚动条是否显示(官网说滚动条不显示，但过滤数据的功能还在，过滤数据请移步官网去看具体配置项说明)
                this.endValue2 = (10 / this.AlarmList.length) * 100;
              } else {
                this.zoomShow2 = false;
                this.endValue2 = 100;//这里一定要注意不显示滚动条的时候一定要把滚动条结束位置设置一下，不然会有bug
              }

              this.buildAlarmStatisticChart()
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //配置动火轮播表格
    createFireTable(fileList){
      if(fileList.length>0 && fileList!=null){
        this.config = {
          header: ['<span style="color:#40A9FF;">公司名称</span>','<span style="color:#40A9FF;">动火类型</span>', '<span style="color:#40A9FF;">数量</span>'],
          headerBGC: 'rgba(18,37,71,0.6)', // 表头背景色
          oddRowBGC: '#061733', // 奇数行背景色
          evenRowBGC: 'rgba(18,37,71,0.6)', // 偶数行背景色
          waitTime: 2000, // 滚动间隔时间
          index: true, // 是否显示序号
          indexHeader: '<span style="color:#40A9FF;">序号</span>', // 序号表头
          carousel: 'page', // 轮播'single'|'page'
          hoverPause: true, // 鼠标悬浮是否暂停滚动
          columnWidth: [60,190, 100, 50], // 列宽
          align: ['center', 'center', 'center', 'center'], // 列对齐方式
          data: fileList
        }
      }else{
        this.config = {
        header: ['<span style="color:#40A9FF;">暂无数据</span>'],
        headerBGC: 'rgba(18,37,71,0.6)', // 表头背景色
        oddRowBGC: '#061733', // 奇数行背景色
        evenRowBGC: 'rgba(18,37,71,0.6)', // 偶数行背景色
        waitTime: 2000, // 滚动间隔时间
        index: true, // 是否显示序号
        indexHeader: '<span style="color:#40A9FF;"></span>', // 序号表头
        carousel: 'page', // 轮播'single'|'page'
        hoverPause: true, // 鼠标悬浮是否暂停滚动
        columnWidth: [100, 200, 100], // 列宽
        align: ['center', 'center', 'center'], // 列对齐方式
        data: []
       }
      }
    },
    //获取动火数据
    getFireData(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/firePermit/statis', data).then(res => {
            if (res.code === 200) {
              if(res.data.subCompanys){//如果有子公司
                let arr = res.data.subCompanys
                for(let i in arr){
                  for(let j in arr[i].firePermitStatis){
                    // let list=[]
                    // list.push(arr[i].companyName)
                    // list.push(arr[i].firePermitStatis[j].levelName)
                    // list.push(arr[i].firePermitStatis[j].count)
                    // this.fireList.push(list)
                    this.fireList.push({
                      name: arr[i].firePermitStatis[j].levelName,
                      value: arr[i].firePermitStatis[j].count
                    })
                  }
                }
              }else{//如果没有子公司
                let arr = res.data
                for(let i in arr){
                  for(let j in arr.firePermitStatis){
                    // let list=[]
                    // list.push(arr.companyName)
                    // list.push(arr.firePermitStatis[j].levelName)
                    // list.push(arr.firePermitStatis[j].count)
                    // this.fireList.push(list)
                    this.fireList.push({
                      name: arr.firePermitStatis[j].levelName,
                      value: arr.firePermitStatis[j].count
                    })
                  }
                }
              }
              console.log(this.fireList)
              // this.createFireTable(this.fireList)
              this.buildFireLevelPieChart()
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //构建动火点等级分类饼图
    buildFireLevelPieChart(){
      let myChart = this.$echarts.init(document.getElementById('fire_level_pie_chart'));
      let option = {
        title: {
          left: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        color:['#FF4D4F','#FFA940','#1890FF'],
        legend: {
          top: 'bottom',
          textStyle: { //图例文字的样式
            color: '#E5E4E5',
            fontSize: 14,
            fontWeight: 400,
          },
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '45%',
            center: ['50%', '40%'],
            data: this.fireList,
            label: {
              normal: {
                show: true,
                // formatter: '{d}%',
                formatter: '{c}',
                color:'#FFFFFF',
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);

      //建议加上以下这一行代码，不加的效果图如下（当浏览器窗口缩小的时候）。超过了div的界限（红色边框）
      window.addEventListener('resize',function() {myChart.resize()});
    },
    //配置设备信息轮播表格
    /*createDeviceTable(deviceList){
      if(deviceList.length>0 && deviceList.length!=null){
        this.config2 = {
          header: ['<span style="color:#40A9FF;">公司名称</span>','<span style="color:#40A9FF;">设备名称</span>', '<span style="color:#40A9FF;">数量</span>'],
          headerBGC: 'rgba(18,37,71,0.6)', // 表头背景色
          oddRowBGC: '#061733', // 奇数行背景色
          evenRowBGC: 'rgba(18,37,71,0.6)', // 偶数行背景色
          waitTime: 2000, // 滚动间隔时间
          index: true, // 是否显示序号
          indexHeader: '<span style="color:#40A9FF;">序号</span>', // 序号表头
          carousel: 'page', // 轮播'single'|'page'
          hoverPause: true, // 鼠标悬浮是否暂停滚动
          columnWidth: [60,190, 100, 50], // 列宽
          align: ['center', 'center', 'center', 'center'], // 列对齐方式
          data: deviceList
        }
      }else{
        this.config2 = {
          header: ['<span style="color:#40A9FF;">暂无数据</span>'],
          headerBGC: 'rgba(18,37,71,0.6)', // 表头背景色
          oddRowBGC: '#061733', // 奇数行背景色
          evenRowBGC: 'rgba(18,37,71,0.6)', // 偶数行背景色
          waitTime: 2000, // 滚动间隔时间
          index: true, // 是否显示序号
          indexHeader: '<span style="color:#40A9FF;"></span>', // 序号表头
          carousel: 'page', // 轮播'single'|'page'
          hoverPause: true, // 鼠标悬浮是否暂停滚动
          columnWidth: [100, 200, 100], // 列宽
          align: ['center', 'center', 'center'], // 列对齐方式
          data: []
        }
      }
    },*/
    //获取设备数据
    getDeviceData(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/device/statis', data).then(res => {
            if (res.code === 200) {
              if(res.data.subCompanys){//如果有子公司
                let arr = res.data.subCompanys
                for(let i in arr){
                  console.log(i)
                  let list=[]
                  list.push(arr[i].companyName)
                  for(let j in arr[i].deviceTotalInfos){
                    list.push(arr[i].deviceTotalInfos[j].count)
                  }
                  this.donghuoLevelxData.push(list)
                }
                console.log(this.donghuoLevelxData)
                this.donghuoLevelStatisticChart()
              }else{//如果没有子公司
                let arr = res.data
                let list=[]
                  list.push(arr.companyName)
                  for(let i in arr.deviceTotalInfos){
                    list.push(arr.deviceTotalInfos[i].count)
                  }
                this.donghuoLevelxData.push(list)
              }
              this.donghuoLevelStatisticChart()
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    getAllPointStatis(){
      let data={
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/statis', data).then(res => {
          if (res.code === 200) {
            console.log(res.data)
            this.allPointsData = res.data ? [res.data] : []
            this.enterpriseName = res.data.companyName || '公司名称'
          }
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    //获取动火点在地图上的位置
    getPoint(){
      let data={
        //"companyId": "6145378898200578"
        "companyId": "6223154483595266"
      }
      this.$post('https://firemanage.igongdi.top/api/bigScreen/enterprise/project/list', data).then(res => {
            if (res.code === 200) {
              for(var item in res.data.projectsAndHotwork[0]){
                if (item == 'hotWorkPermits') {
                  delete res.data.projectsAndHotwork[0][item];
                }
              }
              let arr=res.data.projectsAndHotwork
              for (let i = 0; i < arr.length; i++) {
                let res = JSON.parse(
                    JSON.stringify(arr[i])
                        .replace(/latitude/g, 'lat')
                        .replace(/longitude/g, 'lng')
                );
                this.newPoints.push(res)
              }
            }
          }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

  }
}
</script>
<style lang="stylus" scoped>
.enterprise-site-screen-container
  .el-menu-vertical-demo
    width 85%
    height 78%
    overflow auto
    margin-left 2%
    margin-top 3%
    // background #07152D
  .divider-line
    height 1px
    margin-top 8px
    border-bottom 2px solid #006FCC
</style>
<style lang="stylus">
.enterprise-site-screen-container
  .el-menu-vertical-demo
    .el-icon-arrow-right:before
      content: "\e791"

    /* 修改展开图标到左边 start */
    .collapse-title
      flex: 1 0 90%;
      order: 1;
      img
        margin-right 4px
        vertical-align middle
    .el-collapse-item__header
      flex: 1 0 auto;
      order: -1;
    /* 修改展开图标到左边 end */

    .el-collapse-item__wrap
      background: transparent;
    .el-collapse, .el-collapse-item__wrap
      border-color: #07152D
    
    .el-collapse-item__header
      background: #112A56;
      color: #fff;
      border-color: #07152D;
      padding-left: 20px;
      height: 0.18rem;
      line-height: 0.18rem;
    
    .secondCollapse .el-collapse-item__header
      background: #0D1E3D;
      color: #fff;
      padding-left: 40px

    .thirdCollapse .el-collapse-item__header
      background: #0D1E3D;
      color: #fff;
      padding-left: 60px
    
    .el-collapse-item__content
      padding-bottom: 0;
    
    .tableClass
      color: #fff;
      width:100%;
      border-top: solid 2px #07152D
    
    .tableClass td,.tableClass th
      background: #0D1E3D;
      border-bottom: solid 2px #07152D;
      height: 0.18rem;
      line-height: 0.18rem;
  
</style>
<style>
.time {
  float: right;
  padding: 0.5%;
  color: white;
  font-size: 16px;
  font-weight: normal;
  margin-top:10px
}
.time2{
  float: right;
  color: white;
  font-family:electronicFont;
  font-size: 32px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 30px;
  margin-top:10px
}

.bg-img {
  display:flex;
  position: absolute;
  height: 100%;
  top: 0;
  width: 100vw;
  z-index: -1;
}
.bottom-img{
  position: absolute;
  bottom:0;
  width: 100vw;
  z-index: 999;
}
.copyrightDiv{
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  position: absolute;
  bottom: 12px;
  left:0;
  right:0;
}
.banner{
  position: absolute;
  top:0;
  width: 100vw;
}
.logo-img{
  position: absolute;
  left: 3vh;
  top: 7px;
}
.logoDiv{
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  z-index: 1;
  position: absolute;
  top: 20px;
  left:0;
  right:0;
}

.left,.right{
  display: flex;
  flex:0.53;
  margin-top: 11vh;
  height: 86vh;
  flex-direction: column;
}
.center{
  flex:1;
  margin-top: 11vh;
  height:86vh;
}

.leftBox{
  min-width: 80%;
  width: 100%;
  height: 100%;
  margin-left: 3vh;
  background-image: url('../../../assets/box.png');
  background-repeat: no-repeat;
  color: white;
}
.leftBox1{
  min-width: 80%;
  width: 100%;
  height: 200%;
  overflow: auto;
  margin-left: 3vh;
  background-image: url('../../../assets/leftBox2.png');
  background-repeat: no-repeat;
  color: white;
}

.rightBox{
  min-width: 80%;
  width: 100%;
  height: 100%;
  margin-left: 2vh;
  background-image: url('../../../assets/box.png');
  background-repeat: no-repeat;
  color: white;
}
.rightBox1{
  min-width: 80%;
  width: 100%;
  height: 100%;
  margin-left: 2vh;
  margin-top: 2%;
  background-image: url('../../../assets/box.png');
  background-repeat: no-repeat;
  color: white;
}

.centerBox{
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url('../../../assets/bigbox.png');
  color: white;
}

.qiehuan{
  top: 10px;
  width: 144px;
  height: 31px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  border:0;
  background-image: url('../../../assets/qiehuan.svg');
  cursor: pointer;
  display: inline-block;
  position: relative;
  float: right;
}
.qiehuan:hover {
  background-image: url("../../../assets/qiehuanact.svg");
}

.title{
  margin-top: 1%;
  margin-left: 4%;
  font-size: 21px;
}

#donghuoLevelStatistic_chart{
  width: 86%;
  height: 370px;
  padding-left: 3%;
  padding-top: 2%;
}

#todayAlarm_chart{
  width: 86%;
  height: 230px;
  padding-left: 3%;
  margin-top: -2%;
}

#alarmStatistic_chart{
  width: 86%;
  height: 230px;
  padding-left: 3%;
  margin-top: -2%;
}

#pie_chart{
  width: 100%;
  height: 200px;
  padding-left: 3%;
}

.map{
  width: 100%;
  height: 820px;
}

.enterButton{
  width: 100px;
  margin-left: 27%;
  margin-top: 30px;
  background-image: linear-gradient(to right , #29E5FE, #1E90FD);
  color: black;
  font-weight: bold;
  font-size: 14px;
  border: 0;
  border-radius: 0;
}
/*鼠标点击后移开，恢复本身样式*/
.enterButton, .enterButton:focus:not(.buttonDiv:hover){
  margin-right: 12px;
  border-radius: 0;
  color: black;
  background-image: linear-gradient(to right , #29E5FE, #1E90FD);
}
/*鼠标悬浮，没有按下；鼠标按下后抬起，没有移开*/
.enterButton:focus, .enterButton:hover{
  background-image: linear-gradient(to right , #29E5FE, #1E90FD);
  color: black;
}
/*鼠标按下，没有抬起*/
.enterButton:active {
  background-image: linear-gradient(to right , #29E5FE, #1E90FD);
  color: black;
}


::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}

/*地图标题 infoWindow*/
.BMap_bubble_title{
  color:#fff;
  font-size:18px;
  text-align:left;
  background:transparent !important;
}

.BMap_pop .BMap_top{
  background:#3F4358 !important;
  border:0 !important;
}
.BMap_pop .BMap_center{
  /* width:251px !important; */
  border:0 !important;
  background:#3F4358 !important;
}
.BMap_pop .BMap_bottom{
  border:0 !important;
  background:#3F4358 !important;
}

.BMap_pop div:nth-child(3){
  background:transparent !important;
}
.BMap_pop div:nth-child(3) div{
  border-radius:7px;
  background:#3F4358 !important;
  border:0 !important;
}
.BMap_pop div:nth-child(1){
  border-radius:7px 0 0 0;
  background:transparent !important;
  border:0 !important;
}
.BMap_pop div:nth-child(1) div{
  background:#3F4358 !important;
}
.BMap_pop div:nth-child(5){
  border-radius:0 0 0 7px;
  background:transparent !important;
  border:0 !important;
}
.BMap_pop div:nth-child(5) div{
  border-radius:7px;
  background:#3F4358 !important;
}
.BMap_pop div:nth-child(7){
  background:transparent !important;
}
.BMap_pop div:nth-child(7) div{
  border-radius:7px;
  background:#3F4358 !important;
}

.BMap_pop div:nth-child(8) div{
  /*border-radius:7px;*/
  background:#3F4358 !important;
}
/*窗体阴影*/
.BMap_shadow div:nth-child(5) img{
  margin-left: -1100px !important;
}

.BMap_shadow div:nth-child(4){
  width: 262px !important;
}

/*下面箭头替换为自己本地修改过的*/
img[src="http://api0.map.bdimg.com/images/iw3.png"] {
  display: none;
}
img[src="https://api.map.baidu.com/images/iw3.png"] {
  display: none;
}
</style>

<style lang="stylus" scoped>
@import "../../../styles/base.styl"
.hotwork_video_container
  .hotwork_video_content
    margin 100px 8px 10px
    box-shadow 0 2px 12px 0 rgba(0, 0, 0, .1)

    .hotwork_video_main
      padding 0 20px
      margin-top 0
      .img
        width 30px
        height 30px
        margin-right 5px
        border solid 2px #ccc
        &:hover
          cursor pointer
          border solid 2px #aebcd9
          margin-bottom -2px
    .grid_region
      .video
        // min-height 340px
        margin 0
        position relative
        box-sizing border-box
</style>
