<template>
  <div class="footer-div">
    关于智慧工地平台V1.0.0 | 版权所有 © 上海建工羿云科技有限公司
  </div>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        
      }
    },

    methods: {},
    mounted() {}
  }
</script>

<style lang="stylus" scoped>
  .footer-div
    color #949494
    text-align center
    font-size 14px
    width 100%
    height 60px
    line-height 60px
    background #434343
    position fixed
    bottom 0
    z-index 999
</style>

