<template>
  <div class="menu-container">
    <el-scrollbar class="scrollbarFit">
      <el-collapse-transition>
        <el-menu router
                 class="organization-menu"
                 v-show="!$route.meta.hideSideBar"
                 unique-opened
                 :default-active="$route.path"
                 :collapse="collapse">
          <el-submenu v-for="menu in menus" :key="menu.menuId" :index="menu.url">
            <template slot="title">
              <i :class="menu.iconUrl" class="menu-icon" />
              <span class="menu-title">{{menu.menuName}}</span>
            </template>
            <el-menu-item-group v-for="subMenu in menu.childMenus" :key="subMenu.menuId">
              <el-menu-item :index="subMenu.url">
                <span class="submenu_title">{{subMenu.menuName}}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-collapse-transition>
    </el-scrollbar>
  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: "Sidebar",
    props:{
      collapse: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        currentSystem: '/home', // 参考router里的meta的system
        menus: [{
            "menuId": "831346611736577",
            "menuName": "数据监控看板",
            "url": "/",
            "level": 2,
            "iconUrl": "icon-sjjkkb",
            "childMenus": [{
              "menuId": "831346611736578",
              "menuName": "企业端集团大屏",
              "url": "/enterpriseSiteScreen",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            },{
              "menuId": "8313466117365781",
              "menuName": "企业看板-大屏",
              "url": "/companyScreen",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "831346611736579",
            "menuName": "劳务管理",
            "url": "/labor",
            "level": 2,
            "iconUrl": "icon-lwgl",
            "childMenus": [{
              "menuId": "83134661173658022",
              "menuName": "劳务工人库",
              "url": "/labor",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            },{
              "menuId": "83134661173658033",
              "menuName": "劳务人员统计",
              "url": "/count",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "8313466117365791",
            "menuName": "考勤管理",
            "url": "/attendance",
            "level": 2,
            "iconUrl": "icon-kqgl",
            "childMenus": [{
              "menuId": "8313466117365801",
              "menuName": "项目考勤统计",
              "url": "/attendance",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "8313466117365793",
            "menuName": "劳务安全",
            "url": "/safty",
            "level": 2,
            "iconUrl": "icon-lwaq",
            "childMenus": [{
              "menuId": "83134661173658031",
              "menuName": "三级安全教育",
              "url": "/education",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            },{
              "menuId": "83134661173658032",
              "menuName": "每日晨会",
              "url": "/meeting",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            },{
              "menuId": "83134661173658033",
              "menuName": "预警消息",
              "url": "/warning",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "8313466117365794",
            "menuName": "报表",
            "url": "/report",
            "level": 2,
            "iconUrl": "icon-bb",
            "childMenus": [{
              "menuId": "8313466117365814",
              "menuName": "项目工资报表",
              "url": "/report/wage",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            },{
              "menuId": "83134661173658141",
              "menuName": "考勤统计报表",
              "url": "/report/attendance",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "83134661173657945",
            "menuName": "设置管理",
            "url": "/setting",
            "level": 2,
            "iconUrl": "icon-szgl",
            "childMenus": [{
              "menuId": "83134661173658145",
              "menuName": "危险工种设置",
              "url": "/setting/danger",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          },{
            "menuId": "83134661173657946",
            "menuName": "第三方平台对接",
            "url": "/third",
            "level": 2,
            "iconUrl": "icon-dsfptdj",
            "childMenus": [{
              "menuId": "83134661173658146",
              "menuName": "平台数据对接",
              "url": "/third",
              "level": 3,
              "iconUrl": "icon-dingwei",
              "haveAuthority": true
            }],
            "haveAuthority": true
          }],
      }
    },
    computed: {
      // ...mapState({
      //   currentMenu: (state) => state.layout.currentMenu,
      // }),
    },
    methods: {

    },
    watch: {
      $route: {
        handler(val, oldVal) {
          console.log(oldVal)
          let system = val.meta.system
          if (system) {
            this.currentSystem = system
          }
        },
        immediate: true
      },

      // currentMenu: {
      //   handler(val, oldVal) {
      //     console.log(val, oldVal)

      //   //   if (!this.$isEmpty(JSON.parse(localStorage.getItem('currentMenu')))) {
      //   //     this.menus = JSON.parse(localStorage.getItem('currentMenu')).childMenus || [];
      //   //   }
      //   },
      //   immediate: true,
      //   deep: true,
      // }
    }
  }
</script>

<style lang="stylus" scoped>
  @import "../../../styles/base.styl"
  .menu-container
    height auto
    -webkit-box-shadow 0 2px 12px 0 rgba(0, 0, 0, .1)
    box-shadow 0 2px 12px 0 rgba(0, 0, 0, .1)


</style>

<style lang="stylus">
  @import "../../../styles/base.styl"
  .menu-container
    .scrollbarFit
      height calc(100% - 64px) !important
      .el-scrollbar__wrap
        margin-right 0
        // overflow-x hidden
    .el-menu
      border-right 0
    .el-menu-item
      color rgba(255,255,255,0.6)
      font-family $title-family
      background-color #000000
      &:focus,&.is-active
        color #fff
        background-color #377CED
      &:hover
        color #fff
    .el-submenu__title
      color #fff
      font-family $title-family
      background-color #333
      &:hover
        background-color #000
      i.menu-icon
        color #fff!important
        margin-right 12px
        display inline-block
        width 15px
        height 15px
      .icon-sjjkkb
        background url('../../../icons/png/icon-sjjkkb.png') no-repeat
      .icon-lwgl
        background url('../../../icons/png/icon-lwgl.png') no-repeat
      .icon-kqgl
        background url('../../../icons/png/icon-kqgl.png') no-repeat
      .icon-lwaq
        background url('../../../icons/png/icon-lwaq.png') no-repeat
      .icon-bb
        background url('../../../icons/png/icon-bb.png') no-repeat
      .icon-szgl
        background url('../../../icons/png/icon-szgl.png') no-repeat
      .icon-dsfptdj
        background url('../../../icons/png/icon-dsfptdj.png') no-repeat

    .el-menu-item-group__title
      padding 0

  .el-menu--vertical
    .el-menu
      background-color #000
    .el-menu-item
      color rgba(255,255,255,0.6)
      font-family $title-family
      background-color #000000
      &:focus,&.is-active
        color #fff
        background-color #377CED
      &:hover
        color #fff

</style>
