<template>
  <section class="app-main">
    <!-- <transition name="slide-fade" mode="out-in">
      <div :class="$route.meta.hideSideBar ? 'loading-new-area' : 'loading-area'">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </div>
    </transition> -->
    <router-view></router-view>
  </section>
</template>

<script>
  export default {
    name: "App-main",
    components:{
      
    },
    data() {
      return {}
    }
  }
</script>

<style lang="stylus" scoped>
  .app-main
    min-width 1233px
    min-height 100%
    .loading-new-area
        width 1233px
        margin 0 auto
    .slide-fade-enter-active
      transition all .2s ease
    .slide-fade-leave-active
      transition all .5s cubic-bezier(1.0, 1.0, 0.6, 1.2)
    .slide-fade-enter
      transform translateX(-40px)
      opacity 0
    .slide-fade-leave-to
      transform translateX(30px)
      opacity 0
</style>
